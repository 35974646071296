<template>
  <v-form ref="form" style="max-width: 300px">
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('E-mail')"
            ref="email"
            required
            v-model="email"
            v-on:keyup.enter="attempt"
          />

          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :label="$t('Password')"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            ref="password"
            required
            v-model="password"
            v-on:keyup.enter="attempt"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            color="primary"
            block
            x-large
            elevation="0"
            :loading="appState.authenticating === true"
            @click="attempt"
          >
            {{ $t('Login') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="canWipe">
        <v-col>
          <v-btn
            color="secondary"
            block
            x-large
            elevation="0"
            @click="wipe"
          >
            {{ $t('Wipe local data') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            text
            color="grey"
            :href="passwordResetUrl"
            target="_blank"
          >
            {{ $t('Forgot password?') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
const validator = require('email-validator')

export default {
  name: 'LoginForm',

  props: {
    onLoginAttempt: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    email: '',
    password: '',
    showPassword: false
  }),

  mounted () {
    this.email = _.get(this.$store.state.app, 'abs.user', '').replace(/"/g, '')

    this.$nextTick(() => {
      if (this.email === '') {
        this.$refs.email.$refs.input.focus()
      } else {
        this.$refs.password.$refs.input.focus()
      }
    })
  },

  computed: {
    validated () {
      return this.password.length >= 3 && validator.validate(this.email)
    },

    canWipe() {
      return window.indexedDB.databases !== undefined
    },

    passwordResetUrl () {
      return `${this.$nosework.smoothcomp.baseWebUrl}/sv/password/reset`
    }
  },

  methods: {
    async wipe() {
      const dbs = await window.indexedDB.databases()
      dbs.forEach(db => { window.indexedDB.deleteDatabase(db.name) })

      alert(this.$t('Wipe completed'))
    },

    attempt () {
      this.onLoginAttempt({
        email: this.email,
        password: this.password
      })
    }
  }
}
</script>
