<template>
  <div v-if="!wait && allow">
    <v-card v-show="show" class="transparent" elevation="0">
      <v-container>
        <v-row>
          <v-col align="center">
            <h1>{{ $t('Login') }}</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="center">
            <login-form :onLoginAttempt="onLoginAttempt" />
          </v-col>
        </v-row>

        <v-row v-show="authError">
          <v-col align="center">
            <v-alert type="error">
              {{ $t('Login unsuccessful') }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import LoginForm from '@/components/login-form'

export default {
  name: 'Login',

  components: {
    LoginForm
  },

  data () {
    return {
      authError: false,
      show: false
    }
  },

  computed: {
    wait () {
      return !this.appState.ready
    },

    allow () {
      return this.appState.isAuthenticated === false
    }
  },

  watch: {
    authError (value) {
      if (value) {
        setTimeout(() => {
          this.authError = false
        }, 5000)
      }
    },

    wait () {
      this.decide()
    },

    allow () {
      this.decide()
    }
  },

  mounted () {
    this.decide()

    setTimeout(() => {
      this.show = true
    }, 1000)
  },

  methods: {
    onLoginAttempt ({ email, password }) {
      return this.$nosework.context.auth().authenticate({
        email,
        password,
      }, false, (err, res) => {
        if (err) {
          this.authError = true
        } else {
          setTimeout(() => {
            this.redirect('/event')
          }, 1000)
        }
      })
    },

    decide () {
      if (!this.wait && !this.allow) {
        this.$router.push('/event').catch(() => {})
      }
    }
  }
}
</script>
